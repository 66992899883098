import SelectShop from "./selectShop";
import ShowLeaflet from "./showLeaflet";
import SetRoute from "./maps/setRoute";

const APP_ID = "2aSzumnzExFh9s80Wnw9";
const APP_CODE = "wVuAFhiKyexsqDhaCcvosw";

export default class FindShopService {
  constructor() {
    this.radiusSelector = document.querySelectorAll(".radius-selector");
    this.searchInput = document.querySelector(".search-shop-form input");
    this.searchform = document.querySelector(".search-shop-form");
    this.listContainer = null;
    this.maxResults = 10;
    this.shopsCoords = [];
    this.currentRadius = 5;
    this.init();
  }

  init() {
    if (this.radiusSelector != null) {
      this.handleRadiusSelection();
    }

    this.listContainer = document.createElement("div");
    this.listContainer.classList.add("suggestions-list");
    if (this.searchInput != null) {
      this.searchInput.addEventListener("click", e => {
        this.listContainer.style.display = "block";
      });

      this.searchInput.addEventListener("keyup", e => {
        this.listContainer.innerHTML = "";
        this.handleInput(e);
      });
    }

    this.getShopsCoords();
  }

  getShopsCoords() {
    const shopsList = document.querySelectorAll(".hidden-info p");
    let i = 0;
    shopsList.forEach(shop => {
      this.shopsCoords[i] = { lat: shop.dataset.lat, lng: shop.dataset.lng };
      i++;
    });
  }

  async getSuggestions(value) {
    let response = await fetch(this.suggestionsURL(value));
    let data = await response.json();
    return this.handleSuggestions(data.suggestions);
  }

  async getCoordinates(value) {
    let response = await fetch(this.coordinatesURL(value));
    let data = await response.json();
    return data.Response.View[0].Result[0].Location.DisplayPosition;
  }

  suggestionsURL(value) {
    return `https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=${APP_ID}&app_code=${APP_CODE}&query=${value}&maxresults=10&country=POL
      &maxresults=${this.maxResults}
      &app_id=${APP_ID}
      &app_code=${APP_CODE}
      &query=${encodeURIComponent(value)}`;
  }

  coordinatesURL(value) {
    return `https://geocoder.api.here.com/6.2/geocode.json?app_id=${APP_ID}&app_code=${APP_CODE}&locationid=${value}`;
  }

  async handleInput(e) {
    const inputValue = e.target.value;
    if (inputValue.length > 2) {
      const suggList = await this.getSuggestions(inputValue);
      this.displaySuggestions(suggList);
    }
  }

  handleSuggestions(suggestions) {
    let suggList = [];
    let uniqueLabels = [];

    suggestions.forEach(sugg => {
      let { district, city, street, county, state } = sugg.address;
      let uniqueSuggValues = [
        ...new Set([district, city, street, county, state])
      ];

      let cleanSuggValues = uniqueSuggValues.filter(el => el != null);
      cleanSuggValues[0] = `<span class="highlight">${
        cleanSuggValues[0]
      }</span>`;
      let suggValuesString = cleanSuggValues.join(", ");
      if (uniqueLabels.indexOf(suggValuesString) == -1) {
        const suggObj = {
          locationId: sugg.locationId,
          label: suggValuesString
        };

        suggList.push(suggObj);
        uniqueLabels.push(suggValuesString);
      }
    });
    return suggList;
  }

  displaySuggestions(suggestions) {
    suggestions.forEach(suggestion => {
      let suggestionContainer = document.createElement("p");
      suggestionContainer.innerHTML = `<a href="" class="select-location" data-locationid="${suggestion.locationId}">${suggestion.label}</a>`;
      this.listContainer.appendChild(suggestionContainer);
    });
    document.querySelector(".search-shop-form").appendChild(this.listContainer);
    this.handleLocationSelection();
  }

  handleLocationSelection() {
    const suggestedLocations = document.querySelectorAll(".select-location");
    suggestedLocations.forEach(location => {
      location.addEventListener("click", e => {
        e.preventDefault();

        this.getCoordinates(location.dataset.locationid).then(res =>
          this.searchShops(res)
        );
        this.listContainer.style.display = "none";
      });
    });
  }

  searchShops(coordinates) {
    let data = new FormData(this.searchform);
    data.append("latitude", coordinates.Latitude);
    data.append("longitude", coordinates.Longitude);
    data.append("radius", this.currentRadius);
    data.append("action", "getShops");
    return fetch(myAjax.ajaxurl, {
      method: "POST",
      body: data
    })
      .then(response => response.json())
      .catch(error => console.error("Error:", error))
      .then(response => this.handleResponse(response));
  }

  handleResponse(response) {
    const resultsContainer = document.querySelector(".display-found-shops");
    if (response.foundShops == 0) {
      resultsContainer.innerHTML =
        "<div style='grid-column: 1 / span 4; text-align: center'><h2>Przykro nam. Na tym obszarze nie znaleziono sklepów.</h2></div>";
    } else {
      resultsContainer.innerHTML = response.results;
      setTimeout(() => {
        new SelectShop();
        new ShowLeaflet();
        new SetRoute();
      }, 500);
    }
  }

  handleRadiusSelection() {
    this.radiusSelector.forEach(button => {
      button.addEventListener("click", e => {
        e.preventDefault();
        this.currentRadius = e.target.dataset.radius;
        this.removeSelectedClass();
        e.target.classList.add("selected");
      });
    });
  }

  removeSelectedClass() {
    this.radiusSelector.forEach(button => {
      button.classList.remove("selected");
    });
  }
}
