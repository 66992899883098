export default class ShowLeaflet {
  constructor() {
    this.showLeafletBtns = null;
    this.shopID = 0;
    this.init();
  }

  init() {
    this.showLeafletBtns = document.querySelectorAll(".show-selected-leaflet");
    if (this.showLeafletBtns != null) {
      this.showLeafletBtns.forEach(button => {
        button.addEventListener("click", e => {
          this.setTempCookie(e);
        });
      });
    }
  }

  setTempCookie(e) {
    this.shopID = e.target.dataset.shopid;
    document.cookie = "tempshopid=" + this.shopID + ";path=/";
  }
}
