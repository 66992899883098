//js
if (!global._babelPolyfill) {
  require("babel-polyfill");
}
require("./js/slider");
require("./js/scroll");
require("./js/helper");
require("./js/navigation");
require("./js/main");
require("./js/searchHandle");

//fonts
// require('./fonts/font/stylesheet.css');

// css
import "normalize.css";
require("./sass/style.scss");
