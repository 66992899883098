export default class ShowAllShops {
  constructor() {
    this.apiKey = "NrnBRT_0AD0vRKR9gs-UVQxxc7SImXnbkzwD7xxX8YY";
    this.mapContainer = null;
    this.allShopsMapContainer = null;
    this.allShopsInfo = [];
    this.init();
  }

  init() {
    this.allShopsMapContainer = document.querySelector(".find-shop__map");
    if (this.allShopsMapContainer != null) {
      this.displayAllShops();
    }
  }

  displayAllShops() {
    this.getAllShopsInfo();
    const platform = new H.service.Platform({
      apikey: this.apiKey
    });

    // Obtain the default map types from the platform object:
    const defaultLayers = platform.createDefaultLayers();

    // Instantiate (and display) a map object:
    const coords = { lat: 51.9807384, lng: 19.4915749 };
    const map = new H.Map(
      this.allShopsMapContainer,
      defaultLayers.vector.normal.map,
      {
        zoom: 6,
        center: coords
      }
    );

    // Enable the event system on the map instance:
    const mapEvents = new H.mapevents.MapEvents(map);

    // Add event listeners:
    map.addEventListener("tap", function(evt) {
      // Log 'tap' and 'mouse' events:
      console.log(evt.type, evt.currentPointer.type);
    });

    // Instantiate the default behavior, providing the mapEvents object:
    const behavior = new H.mapevents.Behavior(mapEvents);

    // Add markers to the map:
    const numberOfShops = this.allShopsInfo.length;
    for (let i = 0; i < numberOfShops; i++) {
      const tempLat = this.allShopsInfo[i].lat;
      const tempLng = this.allShopsInfo[i].lng;
      const coords = { lat: tempLat, lng: tempLng };
      const marker = new H.map.Marker(coords);
      const html =
        "<p><strong>" +
        this.allShopsInfo[i].city +
        "</strong><br />" +
        this.allShopsInfo[i].postcode +
        '</p><a href="' +
        this.allShopsInfo[i].url +
        '">Informacje</a>';
      marker.setData(html);
      marker.addEventListener("tap", e => {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        const bubble = new H.ui.InfoBubble(e.target.getGeometry(), {
          // read custom data
          content: e.target.getData()
        });
        // show info bubble
        ui.addBubble(bubble);
      });
      map.addObject(marker);
    }

    // Create the default UI:
    const ui = H.ui.UI.createDefault(map, defaultLayers, "pl-PL");
  }

  getAllShopsInfo() {
    const shopsOptions = [...document.querySelectorAll("option")];
    shopsOptions.splice(0, 1);
    let i = 0;
    shopsOptions.forEach(option => {
      this.allShopsInfo[i] = {
        lat: option.dataset.lat,
        lng: option.dataset.lng,
        url: option.value,
        city: option.dataset.city,
        postcode: option.dataset.postcode
      };
      i++;
    });
  }
}
