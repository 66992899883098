const navSearchForm = document.querySelector(".nav-search-form");
const searchButton = document.querySelector("#search-button");
const closeSearchFormButton = document.querySelector(".close-search-form");

searchButton.addEventListener("click", e => {
  e.preventDefault();
  navSearchForm.classList.add("open");
});

closeSearchFormButton.addEventListener("click", e => {
  e.preventDefault();
  navSearchForm.classList.remove("open");
});
