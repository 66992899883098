export default class ShowSingleShop {
  constructor() {
    this.apiKey = "NrnBRT_0AD0vRKR9gs-UVQxxc7SImXnbkzwD7xxX8YY";
    this.mapContainer = null;
    this.setRouteBtn = null;
    this.lat = 0;
    this.lng = 0;
    this.currentLat = 0;
    this.currentLng = 0;
    this.init();
  }

  init() {
    this.mapContainer = document.getElementById("display-shop-location");
    if (this.mapContainer != null) {
      navigator.geolocation.getCurrentPosition(position => {
        this.getCurrentCoords(position);
      });

      this.showSingleShopLocation();
    }

    this.allShopsMapContainer = document.querySelector(".find-shop__map");
    if (this.allShopsMapContainer != null) {
      this.displayAllShops();
    }
  }

  getCurrentCoords(position) {
    this.currentLat = position.coords.latitude;
    this.currentLng = position.coords.longitude;
  }

  showSingleShopLocation() {
    const platform = new H.service.Platform({
      apikey: this.apiKey
    });

    // Obtain the default map types from the platform object:
    const defaultLayers = platform.createDefaultLayers();

    // Instantiate (and display) a map object:
    const shopInfo = document.querySelector(".shop-details");
    this.lat = shopInfo.dataset.lat;
    this.lng = shopInfo.dataset.lng;
    const coords = { lat: this.lat, lng: this.lng };
    const map = new H.Map(this.mapContainer, defaultLayers.vector.normal.map, {
      zoom: 14,
      center: coords
    });

    // Enable the event system on the map instance:
    const mapEvents = new H.mapevents.MapEvents(map);

    // Add event listeners:
    map.addEventListener("tap", function(evt) {
      // Log 'tap' and 'mouse' events:
      console.log(evt.type, evt.currentPointer.type);
    });

    // Instantiate the default behavior, providing the mapEvents object:
    const behavior = new H.mapevents.Behavior(mapEvents);

    // Add the marker to the map and center the map at the location of the marker:
    const marker = new H.map.Marker(coords);
    map.addObject(marker);

    // Create the default UI:
    const ui = H.ui.UI.createDefault(map, defaultLayers, "pl-PL");
  }
}
