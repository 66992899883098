// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";
import { getScreenWidth } from "./helper";

const screenWidth = getScreenWidth();
let slidesToShow = 0;
if (screenWidth < 740) {
  slidesToShow = 1;
} else {
  slidesToShow = 3;
}

/****** SLIDERS *******/
const sliders = {
  home: $(".products-list")
};

sliders.home.slick({
  appendDots: $(".products-navigation"),
  autoplay: true,
  dots: true,
  nextArrow: $(".slick-next"),
  prevArrow: $(".slick-prev"),
  slidesToShow: slidesToShow,
  slidesToScroll: 1
});
