export default class SelectShop {
  constructor() {
    this.selectShopBtns = null;
    this.shopID = 0;
    this.init();
  }

  init() {
    this.selectShopBtns = document.querySelectorAll(".choose-my-shop");
    if (this.selectShopBtns != null) {
      this.selectShopBtns.forEach(button => {
        button.addEventListener("click", e => {
          this.setCookie(e);
        });
      });
    }
  }

  setCookie(e) {
    this.shopID = e.target.dataset.shopid;
    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = "shopid=" + this.shopID + ";" + expires + ";path=/";
  }
}
