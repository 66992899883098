export default class GetGeolocation {
  constructor() {
    this.currentLat = 0;
    this.currentLng = 0;
    this.init();
  }

  init() {
    const cookieLat = this.getCoordsFromCookies("tempLat");
    const cookieLng = this.getCoordsFromCookies("tempLng");
    if (cookieLat != "" && cookieLng != "") {
      this.currentLat = cookieLat;
      this.currentLng = cookieLng;
    } else {
      navigator.geolocation.getCurrentPosition(async position => {
        await this.getCurrentCoords(position);

        document.cookie = "tempLat=" + this.currentLat + ";path=/";
        document.cookie = "tempLng=" + this.currentLng + ";path=/";
      });
    }
  }

  getCurrentCoords(position) {
    this.currentLat = position.coords.latitude;
    this.currentLng = position.coords.longitude;
  }

  getCoordsFromCookies(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
