import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import ShowLeaflet from "./showLeaflet";
import SelectShop from "./selectShop";
import ShowSingleShop from "./maps/showSingleShop";
import ShowAllShops from "./maps/showAllShops";
import SetRoute from "./maps/setRoute";
import FindShopService from "./findShop";
import GetGeolocation from "./maps/getGeolocation";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();
  new GetGeolocation();

  if (document.body.classList.contains("single-shop")) {
    new ShowSingleShop();
    new ShowLeaflet();
    new SelectShop();
    new SetRoute();
  }

  if (document.body.classList.contains("page-template-leaflet")) {
    new ShowAllShops();
  }

  if (document.body.classList.contains("page-template-find-your-shop")) {
    new FindShopService();
  }

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
